import React, { useState,useEffect } from "react"
import { Container } from "react-bootstrap"
import Modal from "react-bootstrap/Modal"
import Select from "react-select"
import usePagination from "../../hooks/usePagination"
import InnerPagination from "../InnerPagination/InnerPagination"
import Rating from "../Rating/Rating"
import "./ReviewsListing.scss"
import dateFormat from "dateformat"

const {
  ContentModule,
} = require("@starberry/gatsby-theme-utils/Modules/ContentModule")

const ReviewsListing = ({ data, item,revieweeData }) => {
  const reviewee = revieweeData?.revieweeName
  const [search, setSearch] = useState(reviewee ? reviewee : "")
  var allCategoryOption = {
    label: "Filter by department",
    value: "all",
  }

  const removeLowStar=data?.filter(item=>{
    if(item.node.star_rating==="5" || item.node.star_rating==="4"){
      return item
    }
  })

  const sortdData=removeLowStar?.sort((a,b)=>{
    if (a.node.review_date && b.node.review_date) {
      const dateA = new Date(a.node?.review_date);
      const dateB = new Date(b.node?.review_date);
      return dateB - dateA;
      }
      else if (a.node?.review_date) {
          return -1; // a should come before b
      } else if (b.node?.review_date) {
          return 1; // b should come before a
      } else {
          return 0; // if both dates are null or falsy, maintain the current order
      }

    }
  )

  

  var allTeamNameOption = {
    label: "Filter by person",
    value: "all",
  }

  const [selectedTeams, setSelectedTeams] = useState(sortdData)
  const [selectedOption, setSelectedOption] = useState(allCategoryOption)

  let categoryOption = []

  //getting review data which has name
  // const sortedTeam=data.sort((a,b)=>a.node?.team?.name?.localeCompare(b.node?.team?.name))

  const getTeamName = sortdData?.filter(item => {
    if (item.node?.broker&&item.node?.broker?.trim()) {
      return item
    }
  })

  const getDepartment = sortdData?.filter(item => {
    if (item.node?.department) {
      return item
    }
  })
  getDepartment.forEach(element => {
    if (element.node.department&&element.node.department?.trim()!=="") {
      categoryOption.push({
        label: element.node.department?.replace(/_/g, " "),
        value: element.node.department,
      })
    }
  })

  categoryOption.sort((a, b) => a.label?.localeCompare(b.label))
  categoryOption.unshift(allCategoryOption)

  let teamNameOption = []


  getTeamName.forEach(element => {
    if (element.node?.email&&element.node?.email?.trim()!=="") {
      teamNameOption.push({
        label: element.node?.broker?.trim(),
        value: element.node?.email?.toLowerCase()?.trim(),
      })
    }
  })

  teamNameOption.sort((a, b) => a.label?.localeCompare(b.label))
  teamNameOption.unshift(allTeamNameOption)
  
  const uniqueLabels = {};

  const categoriesOptionList = categoryOption.filter(item => {
    const label = item.label.trim().toLowerCase();

    if (!uniqueLabels[label]) {
        uniqueLabels[label] = true;
        return true;
    }
    return false;
});

  //remove team member duplicates//
  const removeTeamdub=new Set(teamNameOption.map(item=>JSON.stringify(item)))
	let teamNamesOptionList = Array.from(removeTeamdub,JSON.parse)
 const newTeamOptions=[]
  teamNamesOptionList?.map(item=>{
    newTeamOptions.push({label:item?.label,value:item.value})
  })
 
  // for pagination
  const itemsPerPage = 12
  const { currentItems, currentPage, setCurrentPage } = usePagination({
    items: selectedTeams,
    itemsPerPage,
  })
  // for pagination

  const handleTabChange = option => {
    setSearch("")
    setSelectedOption(option)
    if (option.value === "all") {
      setSelectedTeams(sortdData)
      return
    }
    let filteredTeams = []
    sortdData.filter(item => {
      if (item?.node?.department) {
        if (item?.node?.department?.includes(option.value)) {
          filteredTeams.push(item)
        }
      }
    })

    setSelectedTeams(filteredTeams)
    setCurrentPage(1)
  }

  const handleSearch = (e)=>{
    e.preventDefault();
    setSearch(e.target.value)
    setSelectedOption(allCategoryOption)
    let filteredTeams = []
    sortdData.filter(item => {
      if (item?.node?.broker) {
        if (item?.node?.broker?.toLowerCase()?.includes(e.target.value?.toLowerCase())) {
          filteredTeams.push(item)
        }
        else if(item?.node?.client_name?.toLowerCase()?.includes(e.target.value?.toLowerCase())){
          filteredTeams.push(item)
        }
        else if(item?.node?.review?.toLowerCase()?.includes(e.target.value?.toLowerCase())){
          filteredTeams.push(item)
        }
      }
    })
    setSelectedTeams(filteredTeams)
    setCurrentPage(1)
  }

   useEffect(()=>{
    if(revieweeData?.revieweeEmail?.trim()){
    let filteredTeams = []
    sortdData.filter(team => {
      if (team.node?.email) {
        if (team.node.email?.toLowerCase()?.trim()===revieweeData?.revieweeEmail?.toLowerCase()?.trim()) {
          filteredTeams.push(team)
        }
      }
    })


    setSelectedTeams(filteredTeams)
  }
  },[revieweeData?.revieweeEmail])

  

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [reviewid, setReviewid] = useState(false)
  const handleShow = event => {
    setReviewid(event)
    setShow(true)
  }

  return (
    <div className="review-listing-wrapper" id={revieweeData?.revieweeEmail?"review-comp":""}>
      <Container>
        <div className="review-opp-content">
          <h2 className="title">{item.title}</h2>
          <p className="description">
            <ContentModule Content={item?.content?.data?.content} />
          </p>
        </div>
        <div className="review-sorting-section">
           <div className="select-department">
              <input
                className="form-control search"
                placeholder="Search by Name"
                onChange={handleSearch}
                value={search}
              />
              <span className="search-icon">
                <i className="icon grey-search-icon" />
              </span>
            </div>
          <div className="select-department">
            <Select
              options={categoriesOptionList}
              isSearchable={false}
              className={"category-select"}
              classNamePrefix={"category-select"}
              //   hideSelectedOptions={true}
              value={selectedOption}
              onChange={handleTabChange}
            />
            <span>
              <i className="icon grey-down-arrow" />
            </span>
          </div>
        </div>

        <div class="masonry">
          {currentItems?.map(({ node, i }) => {
            return (
              <div class="item">
               
               {node?.client_name&&
                <h5>{node?.client_name}</h5>
               }
                {node?.review_date&&
                  <p className="review-createdat">{dateFormat(node?.review_date,"d mmmm yyyy")}</p>
                }
                <Rating starRating={node?.star_rating} />
                
                <p className="description-review">
                <ContentModule
                  Content={
                    node?.review &&
                    node?.review.length > 220
                      ? node?.review.substring(0, 220) + "... "
                      : node?.review
                  }
                />
                </p>
                {node?.review &&
                  node?.review.length > 220 && (
                    <a
                      href="javascript:void(0);"
                      className="btn-text-link"
                      onClick={() => handleShow(node?.strapi_id)}
                    >
                      more
                    </a>
                  )}

                {reviewid && reviewid == node.strapi_id && (
                  <Modal
                    show={show && reviewid == node.strapi_id}
                    size="lg"
                    centered={true}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    dialogClassName="modal-60w modal-form reviewPopup"
                  >
                    <div className="review-wrap">
                      <Modal.Header closeButton className="contact-close-btn">
                        <Modal.Title className="w-100">
                        {node?.client_name&&
                         <h5>{node?.client_name}</h5>
                        }
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        {node?.review_date&&
                          <p className="review-createdat">{dateFormat(node?.review_date,"d mmmm yyyy")}</p>
                        }
                        <Rating starRating={node?.star_rating} />
                        {node?.review&&
                         <p className="description-review">
                        <ContentModule
                          Content={node?.review}
                        />
                        </p>
                        }
                      </Modal.Body>
                    </div>
                  </Modal>
                )}
              </div>
            )
          })}
        </div>
        <InnerPagination
          currentPage={currentPage}
          itemsPerPage={itemsPerPage}
          totalItems={selectedTeams.length}
          setCurrentPage={setCurrentPage}
          listingName="Review"
        />
      </Container>
    </div>
  )
}

export default ReviewsListing
